import React, { MouseEvent } from "react";
import { Link } from "gatsby";
// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";
import "./navbar.css";

export default ({ path }: { path: string }) => {
  const pages = [
    {
      path: "/",
      title: "Home",
      sections: [
        {
          title: "About Me",
          id: "#section-about-me",
        },
        {
          title: "Refsheet",
          id: "#section-refsheet",
        },
        {
          title: "Gallery",
          id: "#section-gallery",
        },
      ],
    },
    {
      path: "/commissions",
      title: "Commissions",
      sections: [
        {
          title: "About",
          id: "#section-about",
        },
        {
          title: "Terminology",
          id: "#section-terminology",
        },
        {
          title: "What you get",
          id: "#section-what-you-get",
        },
        {
          title: "Price",
          id: "#section-price",
        },
        {
          title: "Process",
          id: "#section-process",
        },
        {
          title: "FAQ",
          id: "#section-faq",
        },
        {
          title: "Inquiries",
          id: "#section-inquiry-form",
        },
      ],
    },
  ];

  return (
    <nav className="navbar">
      {pages.map((page, i) => {
        const isCurrentPage = path === page.path;
        return (
          <div
            key={i}
            className={
              isCurrentPage ? "current nav-link-wrapper" : "nav-link-wrapper"
            }
          >
            <AniLink
              to={page.path}
              className="page-link"
              key={i}
              cover
              direction="down"
              bg="var(--color-medium-brown)"
            >
              {page.title}
            </AniLink>
            {(isCurrentPage ? page.sections : []).map((section, i) => (
              <a
                key={i}
                className="anchor-link"
                href={section.id}
                onClick={ev => scrollToElement(section.id, ev)}
              >
                {section.title}
              </a>
            ))}
          </div>
        );
      })}
    </nav>
  );
};

function scrollToElement(selector: string, ev: MouseEvent) {
  ev.preventDefault();

  const element = document.querySelector(selector);
  element?.scrollIntoView({ behavior: "smooth" });

  if (history.pushState) {
    history.pushState(null, "", selector);
  } else {
    location.hash = selector;
  }
}
