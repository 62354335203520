import React, { ReactElement } from "react";
import PropTypes from "prop-types";

import "./reset.css";
import "./layout.css";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import NavBar from "./navbar";

const Layout = ({
  children,
  path: page,
}: {
  children: ReactElement[];
  path: string;
}) => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "icon/square.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      <div>
        <header>
          <Link to="/">
            <div className="icon-wrapper">
              <Img fixed={file.childImageSharp.fixed}></Img>
            </div>
          </Link>
        </header>
        <main>{children}</main>
        <footer>
          <div className="nav-wrapper">
            <NavBar path={page}></NavBar>
          </div>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
